<template>
  <div class="wrapper-header">
    <div class="container-header" :class="{'site-scroll': (getAppScroll /*&& !isMobile*/), 'is-mobile': isMobile}">
      <img class="header-logo" :class="{'site-scroll': (getAppScroll /*&& !isMobile*/), 'is-mobile': isMobile}" src="@/assets/images/logoHeader_300x55.png" @click="onLogoClick()" />
      <div class="header-burger-menu" :class="{'is-mobile': isMobile}" @click="onBurgerMenuClick()" v-click-outside="onClickOutside"><i class="fas fa-bars"></i></div>
      <navigation-ec />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ClickOutside from 'vue-click-outside';

import NavigationEc from '@/components/navigation/Navigation.vue';

export default {
  name: '',
  components: {
    'navigation-ec': NavigationEc,
  },
  directives: {
    ClickOutside,
  },
  props: {
    
  },
  computed: {
    ...mapGetters({
      getAppScroll: 'getAppScroll',
      isMobile: 'getMobile',
      getMenuMobile: 'getMenuMobile',
    }),
  },
  methods: {
    ...mapActions({
      setMenuMobile: 'setMenuMobile',
      toggleMenuMobile: 'toggleMenuMobile',
    }),
    onLogoClick() {
      this.$router.push({ path: '/' });
    },
    onBurgerMenuClick() {
      this.toggleMenuMobile();
      //this.setMenuMobile(true);
    },
    onClickOutside() {
      this.setMenuMobile(false);
    }
  },
  /*
  mounted () {
    // prevent click outside event with popupItem.
    this.popupItem = this.$el
  },
  */
}
</script>

<style lang="scss" scoped>
.wrapper-header {
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  left: 0px;
  top: 0px;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  z-index: 1000;
  border-bottom: 5px solid #d1010f;
  background-color: #FFFFFF;

  .container-header {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    max-width: 1400px;
    //height: 95px;
    transition: all 0.3s;

    &.is-mobile {
      display: block;
      /*flex-flow: column;*/
      //height: auto;
      margin: 0;
      padding: 0;
    }

    &.site-scroll {
      //height: 50px;
    }

    .header-logo {
      position: relative;
      display: block;
      flex: 0 0 auto;
      max-height: 100%;
      height: 95px;
      margin: 0 0 0 15px;
      cursor: pointer;
      transition: height 0.3s;

      &.is-mobile {
        flex: 1 1 auto;
        /*height: 50px;*/
        margin: 0 15px;
      }

      &.site-scroll {
        height: 50px;
      }

      @media screen and (max-width: 685px) {
        height: 75px;
      }
    }

    .header-burger-menu{
      display: none;
      position: absolute;
      top: 2px;
      right: 15px;
      font-size: 35px;
      color: #d1010f;
      cursor: pointer;

      &.is-mobile {
        display: flex;
        flex-flow: column;
        justify-content: flex-start; //center;
        height: 100%;
        top: 8px;
      }
    }
  }
}
</style>
