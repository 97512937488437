<template>
  <div class="wrapper-page wrapper-home">
    <container-gallery :headline="'Zukunft beginnt heute'" :imageUrl="'0-Header-HOME.jpg'" />
    <div class="wrapper-content-home">
      <container-content class="container-content-news" :data="getDataNews" :expandable="true" />
      <container-content class="container-content-projects" :data="getDataProjects" :expandable="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters, /*mapActions*/ } from 'vuex';

import ContainerGallery from '@/components/ContainerGallery.vue';
import ContainerContent from '@/components/ContainerContent.vue';

export default {
  name: '',
  components: {
    'container-gallery': ContainerGallery,
    'container-content': ContainerContent,
  },
  props: {
    
  },
  computed: {
    ...mapGetters({
      getDataById: 'getDataById',
    }),
    getDataNews() {
      const dataNews = this.getDataById('news');
      return dataNews;
    },
    getDataProjects() {
      const dataProjects = JSON.parse(JSON.stringify(this.getDataById('projects')));
      dataProjects.forEach((rubric) => {
        rubric.filters = [];
        rubric.items.sort((a, b) => { // sortiert ProjektArray absteigend nach Datumswert
          return b.date - a.date;
        }); 
        const dataProjectsHome = [];
        dataProjectsHome.push(rubric.items[0]); // fuegt das aktuellste (erste) Projekt an die erste Stelle des neuen Arrays
        rubric.items.splice(0, 1); // entfernt selbiges Projekt aus dem ProjektArray
        for (let i=0; i<4; i++) { // zufaellig aus ProjektArray gezogene Projekte in neuen Array einfuegen
          if(rubric.items.length <= 0) break;
          else {
              const randomIndex = Math.floor(Math.random() * rubric.items.length);
              dataProjectsHome.push(rubric.items[randomIndex]);
              rubric.items.splice(randomIndex, 1);
          }            
        }
        rubric.items = dataProjectsHome;
      });
      return dataProjects;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/_stylesContent.scss";

.wrapper-home {
  
  .wrapper-content-home {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    max-width: 90%; //1400px;

    @media screen and (max-width: 685px) {
      flex-flow: column;
    }

    .container-content {
      
      &:first-child {
        flex: 1 1 60%;
        margin-right: 10px;
      }

      &:last-child {
        flex: 1 1 40%;
        margin-left: 10px;
      }
    }
  }
}
</style>
