<template>
  <div v-if="data" class="container-content">
    <template v-for="(rubric, indexRubric) in getDataFiltered">
      <div :id="rubric.id" class="container-rubric" :key="indexRubric">
        <div class="container-rubric-title">
          <h1>{{ rubric.title }}</h1>
        </div>
        <div v-if="rubric.filters.length > 0" class="container-filter-projects">
          <h3>Filter Projekte<span></span></h3>
          <div class="container-form">
            <template v-for="(filter, index) in rubric.filters">
              <div class="container-content-filter form-check form-check-inline" :key="index">
                <input class="filter-checkbox form-check-input" type="checkbox" :id="`filter-${rubric.id}-${filter.filterId}`" :data-rubric="rubric.id" :value="filter.filterId" :checked="filter.checked" @change="onFilterChange($event)" >
                <label class="filter-label form-check-label" :for="`filter-${rubric.id}-${filter.filterId}`">{{ filter.label }}</label>
              </div>
            </template>
          </div>
        </div>
        <template v-for="(item, indexItem) in rubric.items">
          <div class="container-rubric-item" :class="{ 'item-expandable': expandable, 'collapsed': item.collapsed }" :key="indexItem" :ref="`item-${indexRubric}-${indexItem}`">
            <div v-if="item.image || item.iframe" class="container-item-image" @click="expandable ? onCollapseClick({indexRubric: indexRubric, indexItem: indexItem}) : null">
              <h3>{{ (item.type && item.type.text) ? item.type.text : (item.subheadline ? item.subheadline : "&nbsp;") }}</h3>
              <img v-if="item.image && item.image.url" class="item-image" :src="(getEnv === 'prod' && (item.image.url.includes('/uploads/'))) ? item.image.url : require(`@/assets/images/pages/${item.image.url}`)" @click="onImageClick(item, $event)" />
              <div v-if="item.iframe && item.iframe.url" :class="(item.iframe.type === 'map') ? 'container-content-maps' : 'container-content-video'">
                  <iframe :src="item.iframe.url" :height="item.iframe.height ? item.iframe.height : 'auto'" frameborder="0" style="border:0" allowfullscreen></iframe>
              </div>
            </div>
            <div v-if="item.text || item.job" class="container-item-text" :class="{ 'item-expandable': expandable, 'collapsed': item.collapsed }" :ref="`image-item-${indexRubric}-${indexItem}`" >
              <h2>{{ item.headline }}</h2>
              <div class="item-text" v-html="item.text" />
              <div v-if="item.builder" class="item-builder">{{ item.builder }}</div>
              <div v-if="item.task" class="item-task">{{ item.task }}</div>
              <div v-if="item.period" class="item-period">{{ item.period }}</div>
              <!-- fuer jobs -->
              <div v-if="item.job" class="item-job">{{ item.job }}</div>
              <div v-if="item.tasks" class="item-tasks">{{ item.tasks }}</div>
              <div v-if="item.expectation" class="item-expectation">{{ item.expectation }}</div>
              <div v-if="item.offer" class="item-offer">{{ item.offer }}</div>
              <div v-if="item.deadline" class="item-deadline">{{ item.deadline }}</div>
              <div v-if="item.contact" class="item-contact">
                <p><b>Ansprechpartner:</b></p>
                {{ item.contact }}
              </div>
              <div v-if="item.mail && item.mail.text" class="container-item-mail">
                <a :href="item.mail.url" :target="item.mail.target">{{ item.mail.text }}</a>
              </div>

              <div v-if="item.link && item.link.text" class="container-item-link">
                <router-link v-if="item.link.url.includes('www.engineering-consult.de')" class="subnav-list-link" :to="item.link.url.split('www.engineering-consult.de')[1]">{{ item.link.text }}</router-link>
                <a v-else :href="item.link.url" :target="item.link.target">{{ item.link.text }}</a>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: '',
  props: {
    data: {
      type: Array,
      required: true
    },
    queryFilters: {
      type: String,
      default: '0'
    },
    expandable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      itemCollapsed: true,
    }
  },
  computed: {
    ...mapGetters({
      getEnv: 'getEnv',
    }),
    getDataFiltered() {
      const dataFiltered = JSON.parse(JSON.stringify(this.data));
      dataFiltered.forEach((rubric) => {
        rubric.items.sort(function(a, b){ return b.date - a.date; });
        if (rubric.filters && rubric.filters.length > 0) {
          rubric.items = rubric.items.filter((item) => {
            let showItem = false;
            rubric.filters.forEach((filter) => {
              if (filter.checked && String(item.type.id) === filter.filterId) showItem = true;
            });
            return showItem;
          });
        }
      });
      return dataFiltered;
    }
  },
  methods: {
    onFilterChange(event) {
      const rubricId = event.target.dataset.rubric;
      const filters = this.data.find((rubric) => {
        return rubric.id === rubricId;
      }).filters;

      if (event.target.value === '0') {
        if (filters) {
          filters.forEach((filter) => {
            filter.checked = event.target.checked;
          });
        }
      } else {
        if (filters) {
          filters.find((filter) => {
            return filter.filterId === event.target.value;
          }).checked = event.target.checked;
          if (!event.target.checked) {
            filters.find((filter) => {
              return filter.filterId === '0';
            }).checked = false;
          }
        }
      }
    },
    onCollapseClick(event) {
      //this.itemCollapsed = !this.itemCollapsed;
      this.data[event.indexRubric].items[event.indexItem].collapsed = !this.data[event.indexRubric].items[event.indexItem].collapsed;
      const itemElement = this.$refs[`item-${event.indexRubric}-${event.indexItem}`][0];
      const imageElement = this.$refs[`image-item-${event.indexRubric}-${event.indexItem}`][0];
      itemElement.classList.toggle('collapsed');
      imageElement.classList.toggle('collapsed');
    },
    onImageClick(item, event) {
        if (item.link && item.link.url) {
            if (item.link.url.includes('www.engineering-consult.de')) return;
            window.open(item.link.url);
            event.stopPropagation();
        }
    }
  },
  watch: {
    queryFilters: {
      immediate: true,
      handler(valueNew) {
        if (valueNew) {
          if (valueNew !== '0') {
            this.data[0].filters.forEach((filter) => {
              if (filter.filterId === this.queryFilters) filter.checked = true;
              else filter.checked = false;
            });
          } else {
            this.data[0].filters.forEach((filter) => {
              filter.checked = true;
            });
          }
        }
      }
    }
  },
  created() {
    if (this.data && this.expandable) {
      this.data.forEach((rubric) => {
        rubric.items.forEach((item) => {
          item.collapsed = this.itemCollapsed;
        });
      });
    }
  }
}
</script>

<style lang="scss">
.container-content {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 1400px;
  padding: 10px 0 30px 0;
  transition: all 0.3s;

  .container-rubric {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    margin: 15px 0 15px 0;
    padding: 0 15px;

    .container-rubric-title {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 10px 0 20px 0;
      padding: 5px 0;
      //background-color: #d1010f;

      h1 {
        margin: 0 10px;
        padding: 0;
        font-size: 24px;
        font-weight: normal;
        color: #d1010f; //#FFFFFF;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .container-rubric-item {
      display: flex;
      flex-flow: row wrap;
      margin: 10px 0 30px 0;

      &.item-expandable {
        position: relative;
        flex-flow: column;

        &:after {
          position: absolute;
          top: 0px;
          right: 5px;
          font-family: "Font Awesome 5 Free";
          font-size: 24px;
          font-weight: 900;
          color: #d1010f;
          content: "\f077";
          pointer-events: none;
        }

        &.collapsed {

          &:after {
            content: "\f078";
          }
        }

        .container-item-image {
          //flex: 1 1 100%;
          cursor: pointer;
        }

        .container-item-text {
          //flex: 1 1 100%;
          width: 100%;
          padding: 10px 0 0 0;
          //background-color: #f3f3f3;
          transition: height 0.3s;

          &.collapsed {
            height: 0px;
            overflow: hidden;
          }
        }
      }

      .container-item-image {
        flex: 1 1 40%;
        height: auto;
        padding: 0;

        @media screen and (max-width: 685px) {
          flex: 1 1 auto;
        }

        h3 {
          margin: 0 0 10px 0;
          color: #999;
          font-size: 18px;
          font-weight: normal;
          font-style: italic;
        }

        .item-image {
          width: 100%;
          height: auto;
          padding: 0;
        }

        .container-content-maps,
        .container-content-video {
          width: 100%;
          //height: 30vw;
          min-height: 300px;

          &.container-content-maps {
            height: 30vw;
          }

          &.container-content-video {
            height: 18vw;
          }

          iframe {
            height: 100%;
            width: 100%;
          }
        }
      }

      .container-item-text {
        display: flex;
        flex: 1 1 auto;
        flex-flow: column;
        align-content: flex-start;
        width: 60%;
        padding: 0 0 0 25px;

        @media screen and (max-width: 685px) {
          padding: 10px 0 0 0;
        }

        h2 {
          margin: 0 0 10px 0;
          color: #999;
          font-size: 20px;
          font-weight: normal;
        }

        h4 {
          margin: 20px 0 10px 0;
          font-size: 20px;
          font-weight: bold;
        }

        .item-text {

          p {
            margin: 0 0 10px 0;
            font-size: 18px;
          }

          a {
            margin: 0;
            padding: 0;
            font-size: 16px;
            font-weight: bold;
            color: #d1010f;
          }

          blockquote {
            //margin: 10px 0 10px 25px;
            margin: 10px 0 10px 10px;
            padding: 0;
            font-style: italic;
            border-left: 2px solid #bababa;
            /*background-color: #bababa;*/

            /* &:before {
              font-family: FontAwesome;
              content: '"';
              font-size: 20px;
              position: absolute;
              margin: -5px 0 0 -20px;
            } */

            p {
              margin-left: 5px;
            }

            i {
              margin: 0 0 0 10px;
              padding: 0;
              font-weight: bold;
            }
          }
        }

        .item-task {
          margin: 0 0 10px 0;
          font-size: 18px;
          font-weight: bold;
        }

        .item-builder {
          margin: 0 0 10px 0;
          font-size: 18px;
        }

        .item-period {
          margin: 0 0 10px 0;
          font-size: 18px;
          font-style: italic;
        }

        .item-job {
          margin: 0 0 10px 0;
          font-size: 18px;
        }

        .item-tasks {
          margin: 0 0 10px 0;
          font-size: 18px;
        }

        .item-expectation {
          margin: 0 0 10px 0;
          font-size: 18px;
        }

        .item-offer {
          margin: 0 0 10px 0;
          font-size: 18px;
        }

        .item-deadline {
          margin: 0 0 10px 0;
          font-size: 18px;
        }

        .item-contact {
          margin: 0 0 10px 0;
          font-size: 18px;
        }

        .container-item-mail,
        .container-item-link {

          a {
            margin: 0;
            padding: 0;
            font-size: 16px;
            font-weight: bold;
            color: #d1010f;
          }
        }
      }
    }
  }
}
</style>
