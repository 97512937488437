<template>
  <div class="container-gallery">
    <div class="container-gallery-headline">
      {{ headline }}
    </div>
    <div class="container-gallery-images">
      <img class="gallery-image" :src="require(`@/assets/images/gallery/${imageUrl}`)/*(getEnv === 'prod') ? require(`@/assets/images/gallery/${imageUrl}`) : `./img/${imageUrl}`*/" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: '',
  props: {
    headline: {
      type: String
    },
    imageUrl: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      getEnv: 'getEnv',
    }),
  },
}
</script>

<style lang="scss" scoped>
.container-gallery {
  position: relative;
  width: 100%;
  height: 25vw;
  max-height: 500px;
  min-height: 200px;
  overflow: hidden;
  background-color: #CCCCCC;
  border-bottom: 5px solid #bababa;

  .container-gallery-headline {
    position: absolute;
    bottom: 50px;
    right: 30px;
    font-size: 3vw;
    color: #FFFFFF;
    text-shadow: 0px 0px 3px #d1010f;
  }

  .container-gallery-images {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .gallery-image {
      min-width: 100%;
    }
  }
}
</style>
