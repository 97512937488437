<template>
  <div class="wrapper-content">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    
  }
}
</script>

<style lang="scss" scoped>
.wrapper-content {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: auto;
  margin: 0;
  margin-top: 100px;
  padding: 0;
  background-color: #FFFFFF;

  @media screen and (max-width: 685px) {
    margin-top: 80px;
  }
}
</style>
