<template>
  <div class="wrapper-page wrapper-projects">
    <container-gallery :headline="'Zukunft beginnt heute'" :imageUrl="'2 Header PROJEKTE.png'" />
    <container-content :data="getDataById('projects')" :queryFilters="queryFilters" />
  </div>
</template>

<script>
import { mapGetters, /*mapActions*/ } from 'vuex';

import ContainerGallery from '@/components/ContainerGallery.vue';
import ContainerContent from '@/components/ContainerContent.vue';

export default {
  name: '',
  components: {
    'container-gallery': ContainerGallery,
    'container-content': ContainerContent,
  },
  props: {
    
  },
  data() {
    return {
      queryFilters: '0',
    }
  },
  computed: {
    ...mapGetters({
      getDataById: 'getDataById',
    }),
  },
  /*
  created() {
    if (this.$route.query && this.$route.query.filters) this.queryFilters = this.$route.query.filters;
    else this.queryFilters = '0';
    console.log("mounted() -> queryFilterId", this.queryFilters);
  },
  */
  watch: {
    '$route.query.filters': {
      immediate: true,
      handler(valueNew) {
        if (valueNew) this.queryFilters = valueNew;
        else this.queryFilters = '0';
        console.log("watch -> queryFilters", this.queryFilters);
      }
    }
    /*
    '$route.query.filters'(valueNew) {
      if (valueNew) this.queryFilters = valueNew;
      else this.queryFilters = '0';
      console.log("watch -> queryFilters", this.queryFilters);
    }
    */
  }
}
</script>

<style lang="scss">
@import "@/styles/_stylesContent.scss";

.wrapper-projects {

  .container-filter-projects {
    display: flex;
    flex-flow: column;
    align-items: flex-start; //center;
    width: 100%;
    margin: 10px 0 30px 0;

    h3 {
      margin: 0 0 10px 0;
      color: #999;
      font-size: 18px;
      font-weight: normal;
      font-style: italic;
    }

    .container-form {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      width: 100%;
      padding: 15px;
      background-color: #EFEFEF;

      .container-content-filter {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        //justify-content: center;
        margin: 0 15px;

        .filter-checkbox{
          width: 16px;
          height: 16px;
          cursor: pointer;  
        }

        .filter-label{
          margin: 0 0 0 5px;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
