<template>
  <div id="app">
    <header-ec v-if="getDataLoaded" />
    <content-ec v-if="getDataLoaded" />
    <footer-ec v-if="getDataLoaded" />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';

import HeaderEc from '@/components/Header.vue';
import ContentEc from '@/components/Content.vue';
import FooterEc from '@/components/Footer.vue';

import dataLocal from "@/assets/data/dataLocal.json";
//import dataServerLocal from "@/assets/data/dataServerLocal.json";
import dataServer from "@/assets/data/dataServerLocal.json";

export default {
  name: 'EngineeringConsult',
  components: {
    'header-ec': HeaderEc,
    'content-ec': ContentEc,
    'footer-ec': FooterEc,
  },
  props: {
    
  },
  data() {
    return {
      dataLocal: dataLocal,
      dataServer: dataServer,
    }
  },
  computed: {
    ...mapGetters({
      getDataLoaded: 'getDataLoaded',
      getAppScroll: 'getAppScroll',
      getDataById: 'getDataById',
    }),
  },
  methods: {
    ...mapActions({
      setEnv: 'setEnv',
      setDataLoaded: 'setDataLoaded',
      setAppResolution: 'setAppResolution',
      setAppScroll: 'setAppScroll',
      setDataById: 'setDataById',
    }),
    onAppResize() {
      const resolution = { windowWith: window.innerWidth, windowHeight: window.innerHeight };
      this.setAppResolution(resolution);
    },
    onAppScroll() {
      if (window.scrollY > 100) {
        if (!this.getAppScroll) this.setAppScroll(true);
      } else {
        if (this.getAppScroll) this.setAppScroll(false);
      }
    },
    async loadData() {
      await this.setEnv(process.env.VUE_APP_ENV);

      //console.log('VueAppEnv:', process.env.VUE_APP_ENV);
      //console.log('VueAppLocal:', process.env.VUE_APP_LOCAL);

      if (!Number(process.env.VUE_APP_LOCAL)) {
        await axios
          .get('/json/data.json')
          .then(response => {
            console.log('response', response);
            this.dataServer = response.data;
          })
          .catch(error => {
            console.log('Fehler beim Laden der Daten vom Server', error);
          })
          //.finally(() => this.loading = false)
      }

      // Daten Leistungen (lokal)
      await this.setDataById({ id: 'services', data: this.dataLocal.services });
      await this.setDataById({ id: 'contact', data: this.dataLocal.contact });

      // Daten Home (lokal + server)
      const dataNews = [
        { 
          id: 'aktuelles',
          title: 'AKTUELLES',
          filters: [],
          items: this.dataServer.news
        }
      ];
      await this.setDataById({ id: 'news', data: dataNews });

      // Daten Projekte (lokal + server)
      const dataProjects = [
        { 
          id: 'projekte',
          title: 'PROJEKTE',
          filters: [
            { label: "Alle", filterId: '0', checked: true },
            { label: "Verwaltung", filterId: '1', checked: true },
            { label: "Bildung", filterId: '2', checked: true },
            { label: "Kultur", filterId: '3', checked: true },
            { label: "Kirche", filterId: '4', checked: true },
            { label: "Gesundheit", filterId: '5', checked: true },
            { label: "Wohn-/Geschäftshäuser", filterId: '6', checked: true },
          ],
          items: this.dataServer.projects
        }
      ];
      await this.setDataById({ id: 'projects', data: dataProjects });

      // Daten Ueber Uns (lokal + server)
      this.dataLocal.about.find((rubric) => {
        return rubric.id === 'stellenangebote';
      }).items.push(...this.dataServer.jobs);
      await this.setDataById({ id: 'about', data: this.dataLocal.about });
      console.log(this.dataLocal.about)
    
      this.setDataLoaded(true);
    }
  },
  created() {
    window.addEventListener('scroll', this.onAppScroll);
    this.loadData();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onAppResize);
    });
    this.onAppResize();
  },
  destroyed() {
    window.removeEventListener('scroll', this.onAppScroll);
  },
}
</script>

<style lang="scss">
html{
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #BABABA;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body{
  margin: 0;
  padding: 0;
  font-family: OpenSans, Arial, sans-serif;
  font-size: 14px;
  text-align: left;
  color: #333333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
}

#app {
  
}
</style>
 