<template>
  <div class="wrapper-page wrapper-about">
    <container-gallery :headline="'Zukunft beginnt heute'" :imageUrl="'3 Header ÜBER UNS.png'" />
    <container-content :data="getDataById('about')" />
  </div>
</template>

<script>
import { mapGetters, /*mapActions*/ } from 'vuex';

import ContainerGallery from '@/components/ContainerGallery.vue';
import ContainerContent from '@/components/ContainerContent.vue';

export default {
  name: '',
  components: {
    'container-gallery': ContainerGallery,
    'container-content': ContainerContent,
  },
  props: {
    
  },
  computed: {
    ...mapGetters({
      getDataById: 'getDataById',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/_stylesContent.scss";

.wrapper-about {
  
  
}
</style>
