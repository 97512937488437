import Vue from 'vue';
import VueRouter from 'vue-router';
import PageHome from '@/components/pages/home/Home.vue';
import PageServices from '@/components/pages/services/Services.vue';
import PageProjects from '@/components/pages/projects/Projects.vue';
import PageAbout from '@/components/pages/about/About.vue';
import PageContact from '@/components/pages/contact/Contact.vue';
import PageImprint from '@/components/pages/contact/Imprint.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: PageHome
  },{
    path: '/leistungen',
    name: 'Leisungen',
    component: PageServices
  },{
    path: '/projekte',
    name: 'Projekte',
    component: PageProjects,
    props: true
  },{
    path: '/ueber-uns',
    name: 'UeberUns',
    component: PageAbout
  },{
    path: '/kontakt',
    name: 'Kontakt',
    component: PageContact
  },{
    path: '/impressum',
    name: 'Impressum',
    component: PageImprint
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 80 }
      }
    } else return { x: 0, y: 0 }
  },
});

/*
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
})
*/

export default router;
