import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    env: 'prod',
    dataLoaded: false,
    appResolution: {},
    appScroll: false,
    isMobile: false,
    showMenuMobile: false,
    data: {
      news: [],
      services: [],
      projects: [],
      about: [],
      contact: [],
    },
  },
  mutations: {
    SET_ENV(state, payload) {
      state.env = payload;
    },
    SET_DATA_LOADED(state, payload) {
      state.dataLoaded = payload;
    },
    SET_APP_RESOLUTION(state, payload) {
      state.appResolution = payload;
    },
    SET_APP_SCROLL(state, payload) {
      state.appScroll = payload;
    },
    SET_MOBILE(state, payload) {
      state.isMobile = payload;
    },
    SET_MENU_MOBILE(state, payload) {
      state.showMenuMobile = payload;
    },
    TOGGLE_MENU_MOBILE(state) {
      state.showMenuMobile = !state.showMenuMobile;
    },
    SET_DATA_BY_ID(state, payload) {
      if (state.data[payload.id]) {
        Vue.set(state.data, payload.id, payload.data);
      }
    },
  },
  actions: {
    setEnv({commit}, payload) {
      commit('SET_ENV', payload);
    },
    setDataLoaded({commit}, payload) {
      commit('SET_DATA_LOADED', payload);
    },
    setAppResolution({commit}, payload) {
      commit('SET_APP_RESOLUTION', payload);
      if (payload.windowWith > 1199) commit('SET_MOBILE', false);
      else commit('SET_MOBILE', true);
    },
    setAppScroll({commit}, payload) {
      commit('SET_APP_SCROLL', payload);
    },
    setMenuMobile({commit}, payload) {
      commit('SET_MENU_MOBILE', payload);
    },
    toggleMenuMobile({commit}) {
      commit('TOGGLE_MENU_MOBILE');
    },
    setDataById({commit}, payload) {
      commit('SET_DATA_BY_ID', payload);
    }
  },
  getters: {
    getEnv(state) {
      return state.env;
    },
    getDataLoaded(state) {
      return state.dataLoaded;
    },
    getAppResolution(state) {
      return state.appResolution;
    },
    getAppScroll(state) {
      return state.appScroll;
    },
    getMobile(state) {
      return state.isMobile;
    },
    getMenuMobile(state) {
      return state.showMenuMobile;
    },
    getDataById: (state) => (id) => {
      return state.data[id];
    },
  },
  modules: {

  }
});
