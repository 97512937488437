<template>
  <div class="wrapper-footer">
    <div class="container-footer">
      <div class="container-contact">
        <p>tel 0721-91219-0</p>
        <a href="mailto:mail@engineering-consult.de">mail@engineering-consult.de</a>
      </div>
      <nav>
        <ul class="nav-list">
          <li class="nav-list-item">
            <router-link class="footer-nav-headline" to="/" title="Home">HOME</router-link>
          </li>
          <li class="nav-list-item">
            <router-link class="footer-nav-headline" to="/leistungen" title="Leistungen">LEISTUNGEN</router-link>
            <ul class="subnav-list">
              <li class="subnav-list-item">
                <router-link class="footer-nav-element" to="/leistungen#projektierung" title="Projektierung">Projektierung</router-link>
              </li>
              <li class="subnav-list-item">
                <router-link class="footer-nav-element" to="/leistungen#beratung" title="Beratung">Beratung</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-list-item">
            <router-link class="footer-nav-headline" to="/projekte?filters=0" title="Projekte">PROJEKTE</router-link>
            <ul class="subnav-list">
              <li class="subnav-list-item">
                <router-link class="footer-nav-element" to="/projekte?filters=1" title="Verwaltungsgebäude">Verwaltungsgebäude</router-link>
              </li>
              <li class="subnav-list-item">
                <router-link class="footer-nav-element" to="/projekte?filters=2" title="Bildungseinrichtungen">Bildungseinrichtungen</router-link>
              </li>
              <li class="subnav-list-item">
                <router-link class="footer-nav-element" to="/projekte?filters=3" title="Kultureinrichtungen">Kultureinrichtungen</router-link>
              </li>
              <li class="subnav-list-item">
                <router-link class="footer-nav-element" to="/projekte?filters=4" title="Kirchliche Einrichtungen">Kirchliche Einrichtungen</router-link>
              </li>
              <li class="subnav-list-item">
                <router-link class="footer-nav-element" to="/projekte?filters=5" title="Gesundheitseinrichtungen">Gesundheitseinrichtungen</router-link>
              </li>
              <li class="subnav-list-item">
                <router-link class="footer-nav-element" to="/projekte?filters=5" title="Wohn- und Geschäftshäuser">Wohn- und Geschäftshäuser</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-list-item">
            <router-link class="footer-nav-headline" to="/ueber-uns" title="Über uns">ÜBER UNS</router-link>
            <ul class="subnav-list">
              <li class="subnav-list-item">
                <router-link class="footer-nav-element" to="/ueber-uns#unternehmen" title="Unternehmen">Unternehmen</router-link>
              </li>
              <li class="subnav-list-item">
                <router-link class="footer-nav-element" to="/ueber-uns#mitarbeiter" title="Mitarbeiter">Mitarbeiter</router-link>
              </li>
              <li class="subnav-list-item">
                <router-link class="footer-nav-element" to="/ueber-uns#stellenangebote" title="Stellenangebote">Stellenangebote</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-list-item">
            <router-link class="footer-nav-headline" to="/kontakt" title="Kontakt">KONTAKT</router-link>
            <ul class="subnav-list">
              <li class="subnav-list-item">
                <router-link class="footer-nav-element" to="/kontakt#uebersicht" title="Übersicht">Übersicht</router-link>
              </li>
              <li class="subnav-list-item">
                <router-link class="footer-nav-element" to="/impressum" title="Impressum">Impressum</router-link>
              </li>
              <li class="subnav-list-item">
                <router-link class="footer-nav-element" to="/impressum#privacy" title="Datenschutz">Datenschutz</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-list-item">
            <a class="footer-nav-headline" href="/kundenbereich" target="_blank" title="Kundenbereich">KUNDENBEREICH</a>
            <ul class="subnav-list">
              <li class="subnav-list-item">
                <a class="footer-nav-element" href="http://www.facebook.com/ENGconsult" target="_blank"><i class="fab fa-facebook"></i> Facebook</a>
              </li>
              <!-- <li class="subnav-list-item">
                <a class="footer-nav-element" href="https://twitter.com/_ENConsult.html" target="_blank"><i class="fab fa-twitter"></i> Twitter</a>
              </li> -->
              <li class="subnav-list-item">
                <a class="footer-nav-element" href="https://www.youtube.com/channel/UC8cN_oo59iWRF9q1VCtDFNw" target="_blank"><i class="fab fa-youtube"></i> Youtube</a>
              </li>
              <li class="subnav-list-item">
                <a class="footer-nav-element" href="https://www.competitionline.com/de/bueros/engineering-consult-albertschaar-beratende-ingenieure-tga-partgmbb-49637/wettbewerbe" target="_blank"><img class="competitionline-logo" src="@/assets/images/logoCompetitionline.png"> Competitionline</a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
    <div class="footer-logo"></div>
  </div>
</template>

<script>
export default {
  name: '',
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
.wrapper-footer {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  left: 0px;
  top: 0px;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  background-color: #747474;

  .container-footer {
    position: relative;
    width: 100%;
    max-width: 1000px;

    .container-contact {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin: 25px 0 5px 0;

      p, a {
        margin: 0;
        padding: 0;
        color: #fff;
        font-size: 18px;
        text-decoration: none;
      }

      p {
        margin-right: 30px;
      }
    }

    nav {
      width: 100%;
      padding: 30px 0 30px 0;
      font-size: 14px;
      color: #FFFFFF;
      overflow: hidden;

      ul.nav-list {
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        padding: 0;

        @media screen and (max-width: 685px) {
          display: block;
        }

        li.nav-list-item {
          display: inline-block;
          text-align: left;

          @media screen and (max-width: 685px) {
            display: block;
            text-align: center;
          }

          &:not(:last-child){

            @media screen and (max-width: 685px) {
              margin-bottom: 30px;
            }
          }

          .footer-nav-headline{
            font-weight: bold;
            color: inherit;
          }

          ul.subnav-list {
            padding: 0;

            li.subnav-list-item {
              //display: block;
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              margin: 5px 0 0 0;

              &:first-child{
                margin-top: 15px;
              }

              .footer-nav-element{
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                margin: 0 0 5px 0;
                color: inherit;
                text-decoration: none;

                :first-child{
                    margin-right: 5px;
                }
              }

              .competitionline-logo {
                width: 17px;
              }
            }
          }
        }
      }
    }
  }
  .footer-logo{
    width: 100%;
    height: 40px;
    background-image: url("../assets/images/logoFooter_160x40_neu.png");
    background-repeat: no-repeat;
    background-position: center; 
    background-color: #000000;
  }
}
</style>
