<template>
  <div v-show="!isMobile || getMenuMobile" class="container-navigation" :class="{'site-scroll': (getAppScroll /*&& !isMobile*/), 'is-mobile': isMobile}">
    <ul class="nav-list" :class="{'is-mobile': isMobile}">
      <li class="nav-list-item" :class="{'is-mobile': isMobile}">
        <router-link class="nav-list-link" :class="{'is-mobile': isMobile}" to="/" exact title="Home">HOME</router-link>
      </li>
      <li id="nav-list-item-leistungen" class="nav-list-item" :class="{'is-mobile': isMobile}">
        <router-link class="nav-list-link" :class="{'is-mobile': isMobile}" to="/leistungen" title="Leistungen">LEISTUNGEN</router-link>
        <ul v-show="!isMobile" class="subnav-list">
          <li class="subnav-list-item">
            <router-link class="subnav-list-link" to="/leistungen#projektierung">Projektierung</router-link>
          </li>
          <li class="subnav-list-item">
            <router-link class="subnav-list-link" to="/leistungen#beratung">Beratung</router-link>
          </li>
        </ul>
      </li>
      <li id="nav-list-item-projekte" class="nav-list-item" :class="{'is-mobile': isMobile}">
        <router-link class="nav-list-link" :class="{'is-mobile': isMobile}" to="/projekte?filters=0" title="Projekte">PROJEKTE</router-link>
        <ul v-show="!isMobile" class="subnav-list">
          <li class="subnav-list-item">
            <router-link class="subnav-list-link" to="/projekte?filters=1">Verwaltungsgebäude</router-link>
          </li>
          <li class="subnav-list-item">
            <router-link class="subnav-list-link" to="/projekte?filters=2">Bildungseinrichtungen</router-link>
          </li>
          <li class="subnav-list-item">
            <router-link class="subnav-list-link" to="/projekte?filters=3">Kultureinrichtungen</router-link>
          </li>
          <li class="subnav-list-item">
            <router-link class="subnav-list-link" to="/projekte?filters=4">Kirchliche Einrichtungen</router-link>
          </li>
          <li class="subnav-list-item">
            <router-link class="subnav-list-link" to="/projekte?filters=5">Gesundheitseinrichtungen</router-link>
          </li>
          <li class="subnav-list-item">
            <router-link class="subnav-list-link" to="/projekte?filters=6">Wohn- und Geschäftshäuser</router-link>
          </li>
        </ul>
      </li>
      <li id="nav-list-item-ueber-uns" class="nav-list-item" :class="{'is-mobile': isMobile}">
        <router-link class="nav-list-link" :class="{'is-mobile': isMobile}" to="/ueber-uns" title="Über uns">ÜBER UNS</router-link>
        <ul v-show="!isMobile" class="subnav-list">
          <li class="subnav-list-item">
            <router-link class="subnav-list-link" to="/ueber-uns#unternehmen">Unternehmen</router-link>
          </li>
          <li class="subnav-list-item">
            <router-link class="subnav-list-link" to="/ueber-uns#mitarbeiter">Mitarbeiter</router-link>
          </li>
          <li class="subnav-list-item">
            <router-link class="subnav-list-link" to="/ueber-uns#stellenangebote">Stellenangebote</router-link>
          </li>
        </ul>
      </li>
      <li id="nav-list-item-kontakt" class="nav-list-item" :class="{'is-mobile': isMobile}">
        <router-link class="nav-list-link" :class="{'is-mobile': isMobile}" to="/kontakt" title="Kontakt">KONTAKT</router-link>
        <ul v-show="!isMobile" class="subnav-list">
          <li class="subnav-list-item">
            <router-link class="subnav-list-link" to="/kontakt#uebersicht">Übersicht</router-link>
          </li>
          <li class="subnav-list-item">
            <router-link class="subnav-list-link" to="/impressum">Impressum</router-link>
          </li>
        </ul>
      </li>
      <li class="nav-list-item" :class="{'is-mobile': isMobile}">
        <a class="nav-list-link" :class="{'is-mobile': isMobile}" href="/kundenbereich" target="_blank" title="Kundenbereich">KUNDENBEREICH</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: '',
  props: {
    
  },
  computed: {
    ...mapGetters({
      getAppScroll: 'getAppScroll',
      isMobile: 'getMobile',
      getMenuMobile: 'getMenuMobile',
    }),
  },
  methods: {
    ...mapActions({
      setMenuMobile: 'setMenuMobile',
    }),
  },
  /*
  mounted () {
    // prevent click outside event with popupItem.
    this.popupItem = this.$el
  },
  */
}
</script>

<style lang="scss" scoped>
.container-navigation {
  flex: 1 1 100%;
  //height: 95px;
  transition: height 0.3s;

  &.is-mobile {
    flex: none;
    height: auto;
    border-top: 1px solid #d1010f;
  }

  &.site-scroll {
    transition: height 0.3s;
    //height: 50px;
  }

  ul.nav-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end; /*space-evenly;*/
    align-items: flex-end;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    &.is-mobile {
      display: flex;
      flex-flow: column;
      justify-content: center;
      height: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li.nav-list-item {
      position: relative;
      display: flex;
      flex: 0 1 auto;
      align-items: center;

      &.is-mobile {
        flex-flow: column;
        align-items: stretch;
        width: 100%;
        border-top: 1px solid #d1010f;
      }

      .nav-list-link {
        height: 50px;
        padding: 0 20px;
        font-size: 18px;
        line-height: 3.2;
        color: #d1010f;
        text-decoration: none;

        &:hover,
        &.router-link-active,
        &.router-link-exact-active {
          color: #FFFFFF;
          background-color: #d1010f;
        }

        &.is-mobile {
          flex: 1 1 100%;
          text-align: center;

          &.router-link-exact-active {
            //display: none;
          }
        }
      }

      &:hover ul.subnav-list {
          display: block;
        }

      ul.subnav-list {
        display: none;
        position: absolute;
        width: 220px; /* IE */
        width: -moz-max-content;
        width: -webkit-max-content;
        width: -o-max-content;
        width: max-content;
        min-width: 120px;
        max-width: 220px;
        height: auto;
        max-height: 500px;
        top: 50px;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0;
        background-color: #FFFFFF; /*rgba(255, 255, 255, 0.9);*/
        border: 1px solid #d1010f;
        border-top: 5px solid #d1010f;
        font-size: 18px;
        font-weight: normal;
        transition: all 0.3s ease-in;
        list-style: none;
        overflow: auto;

        .subnav-list-item {
          margin: 0;
          padding: 9px 10px 7px 10px;
          border-top: 1px solid #d1010f;
          text-overflow: ellipsis;
          color: #d1010f;
          overflow: hidden;
          cursor: pointer;
          transition: all 0.3s;

          &:first-child {
            border-top: 0px;
          }

          &:hover {
            background-color: #d1010f;
            color: #FFFFFF;
          }

          .subnav-list-link {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
